<template >
  <div class=" inicio  ">  
    
    <div class="container d-flex justify-content-center">

      <div class="row ">
        
      <div class="fondo  d-flex align-items-center">
  
        <div class="col-11">
          <div class="d-flex  imagenes"   >
              <div class="ml-3 img1" >
                <img v-if="!isMovil" src="@/assets/logo-ieebcs.png" alt="PREP" class=" borde d-block img-fluid mx-auto mb-4">
                <!-- <img src="@/assets/logo-ieebcs.png" alt="PREP" class="d-block img-fluid mx-auto mb-4" v-else> -->
              </div>
  
              <div class="ml-3 img2 " >
                <img v-if="!isMovil" 
                src="@/assets/generales/PREP_2024.png" 
                alt="PREP" 
                class="d-block img-fluid mx-auto mb-4" 
                 >
                <!-- <img src="@/assets/generales/prep-2024.png" alt="PREP" class="d-block img-fluid mx-auto mb-4" v-else> -->
              </div>
  
          </div>
          <div class=" img3 " >
            <img  v-if="isMovil" 
            src="@/assets/mainLogo.png"             
            alt="PREP" 
            class="d-block img-fluid mx-auto mb-4"
            :style="{ width: '250px', height: '250px' }" >

          </div>
          
  
          <h1 class="mb-5 ml-3">
            Elecciones Estatales de <br /> <span class="primario">Baja California Sur</span> 2024
          </h1>
  
          <div class="vmovil mb-5  col-lg-7  ">
           <p>
              El Programa de Resultados Electorales Preliminares (PREP) inicia la publicación 
              el 2 de junio a partir de las 20:00 horas, hora local.
            </p>
  
            <p>
              Los resultados presentados son preliminares,
              tienen un carácter informativo y no son definitivos,
              por tanto carecen de efectos jurídicos.
            </p>
  
            <p>
              El miércoles 5 de junio del 2024 darán inicio los Cómputos Distritales,
              los cuales determinan los resultados electorales en la Entidad.
            </p>
          </div>
  
          
            <router-link to="/D/ENT/VPC" class="btn btn-primary btn-home mb-5 ml-3 ">
            Consulta Resultados Preliminares
            </router-link>
          
        </div>
  
  
      </div>
  
  
  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      isMovil: true,
    };
  },
  mounted() {
    // Verificar si la pantalla es de tamaño móvil
    this.isMovil = window.innerWidth < 768;

    // Escuchar cambios en el tamaño de la pantalla
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      // Actualizar el estado cuando cambia el tamaño de la pantalla
      this.isMovil = window.innerWidth < 768;
    },
  },
  computed: {
    esOscuro() {
      return this.$store.state.esOscuro;
    },
  },
  metaInfo: {
    title: 'IEEBCS - PREP 2024',
  },
  beforeDestroy() {
    // Limpiar el evento al destruir el componente
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.inicio {  
  height: 100vh;
  position: relative;
  /* Añade la imagen de fondo */
  background-image: url('../assets/generales/fondo principal.png');
  background-size: cover;
  background-position: bottom; 
  background-repeat: no-repeat;
  
  
}
.btn-home {
  text-align: center;
  width: 100%;
  max-width: 358px;
  padding: 16px;
  // display: block;
  margin: 0 auto;
  background-color: #C791A8;
  border-radius: 8px;
  border-color: #C791A8;
  font-weight: bolder;
  color:#000000;

  &:active {
    background-color: #C791A8 !important;
    border-color: initial !important;
  }
  &:hover {
    background-color: darken(#C791A8, 5%) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
}

.col-12 {
  margin-top: 3rem;

  h3 {
    text-align: center;
    font-weight: 700;
  }
  @media(min-width: 992px) {
    margin-top: 7rem;
  }
}
span{
      color:#582D73;

}

// img {
//   max-width: 160px;
// }

//Versión escritorio
@media (min-width: 768px) {
 
  .fondo{
   background-image: url('../assets/generales/fondo secundario.png');
  background-repeat: no-repeat;
  box-shadow: 0px 0px 10px 0px #000000b9; 
  border-radius: 10px;
  background-color: #fff;
  background-size: cover;
  // width: 65vw;  
  // max-width: 65vw;
  margin-top: 30px;
  
  
  .imagenes{
    margin-top: 25px;
    .img1{
        max-width: 160px;
    }
    .img2{
      padding-top: 15px;
      width: 250px;
    }
   
  }
    h1{
      color: #000000;
    }
    p{ 
      color: #000000;
      font-weight: 500;

    }
    .borde{
      border-right:  3px solid rgba(101, 100, 101, 0.243)!important;
      padding-right: 10px;
    }
}
    


}
//version movil
@media (max-width: 767px) { 
 .img1, .img2{
   display: none;
 }
 
 h1{
  text-align: center;
  font-weight: bold;
  font-size:24px ;
  margin-right: 20px;
 }
 .vmovil{
// justify-content: center;
  p{
    text-align: justify;
  }
}


}
</style>
