<template>
  <div></div>
</template>
<script>
export default {
  name: 'PaginaNoEncontrada',
  created () {
    window.location.href = '/#/G/ENT/VPC'
  }
}
</script>